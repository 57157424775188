<template>
  <div ref="v-popup-wrapper" class="v-popup-wrapper">
    <div class="v-popup" :class="width">
      <div :class="closePosition?closePosition:''" class="v-popup__header">
        <span class="v-popup__header__title">{{ title }}</span>
        <span class="v-popup__header__close bold" @click="closePopup">✕</span>
      </div>
      <div class="v-popup__content" :class="width">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'v-popup',
  props: ['title', 'closePosition', 'width'],
  methods: {
    closePopup() {
      this.$emit('closePopup')
    }
  },
  mounted() {
    var vm = this
    document.addEventListener('click', function (item) {
      if (item.target === vm.$refs['v-popup-wrapper']) {
        vm.closePopup()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.w100 {
  width: 100%;
  overflow-y: auto;
}

.v-popup-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(210, 210, 210, 0.5);
  z-index: 8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.big-icon {
  font-size: 50px !important;
}

.center {
  display: flex;
  justify-content: center;
  margin: 10px;
  font-size: larger;
  text-align: center;
}

.w {
  min-width: 500px;
}

.v-popup {
  color: #3d3d3d;
  z-index: 10;
  padding: 33px;
  position: fixed;
  justify-content: center;
  width: available;
  max-width: 550px;
  background: white;
  box-shadow: -1px 6px 9px 3px rgba(0, 0, 0, 0.09);
  border-radius: 9px;

  &__header, &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__close {
      cursor: pointer;
    }

    &__title {
      font-family: Gotham, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__content {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: left;
  }
}

.form-group {
  line-height: 35px;
  width: 100%;

  input::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }

  input {
    width: 100%;
    font-family: Gotham-Pro, sans-serif;
    font-size: 20px;
    color: #3d3d3d;
    font-weight: normal;
    background: white;
    border: 1px solid green;
    box-sizing: border-box;
  }
}

.right-close {
  justify-content: space-between !important;
}

</style>
