<template>
  <el-card class="box-card">
    <v-popup
        v-show="create"
        title="Добавить"
        @closePopup="closePopup"
    >
      <el-form
          ref="form"
          v-loading="loading"
          :model="form"
          :rules="rules"
          label-position="left"
          label-width="auto"
      >

        <el-form-item label="Дата" prop="date">
          <el-date-picker v-model="form.date" autosize placeholder="..." type="date"></el-date-picker>
        </el-form-item>
        <el-form-item label="Время" prop="time_id">
          <el-select v-model="form.time_id" autosize>
            <el-option v-for="hour in hours" :key="hour.id" selected :value="hour.id" :label="hour.title_time"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Тип записи" prop="time_id">
          <el-select v-model="form.requestType" autosize>
            <el-option label="Записи" :key="1" :value="1"></el-option>
            <el-option label="Чекапы" :key="2" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button
            type="primary"
            @click="onSubmit"
        >Добавить
        </el-button>
        <go-back
            @closePopup="closePopup"
        ></go-back>
      </el-form>
    </v-popup>
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Расписание</b>
      <div class="el-button el-button--primary el-button--small"
           @click="create =! create">
        Добавить
      </div>
    </el-row>
    <calendar-view
        :items="items"
        :show-date="showDate"
        :time-format-options="{ hour: 'numeric', minute: '2-digit' }"
        :enable-drag-drop="true"
        :disable-past="disablePast"
        :disable-future="disableFuture"
        :show-times="showTimes"
        :display-period-uom="displayPeriodUom"
        :display-period-count="displayPeriodCount"
        :starting-day-of-week="startingDayOfWeek"
        class="calendar_view theme-default"
        :period-changed-callback="periodChanged"
        :current-period-label="useTodayIcons ? 'icons' : ''"
        :displayWeekNumbers="displayWeekNumbers"
        :enable-date-selection="true"
        :selection-start="selectionStart"
        :selection-end="selectionEnd"
        @date-selection-start="setSelection"
        @date-selection="setSelection"
        @date-selection-finish="finishSelection"
        @drop-on-date="onDrop"
        @click-date="onClickDay"
        @click-item="onClickItem"
    >
      <template #item="{value, weekStartDate, top}">

        <div @click="onClickItem(value)" v-if=" value.originalItem.requestType==1" draggable="true" title=" "
             class="cv-item" :class="value.classes" :style="{top: top}">
                  <span
                      class="startTime">{{ value.originalItem.title_time }}</span>
        </div>
        <div @click="onClickItem(value)" v-if=" value.originalItem.requestType==2" draggable="true" title=" "
             class="cv-item cv-item-offline" :class="value.classes" :style="{top: top}">
                  <span
                      class="startTime">{{ value.originalItem.title_time }}</span>
        </div>
      </template>
      <template #header="{ headerProps }">
        <calendar-view-header slot="header" :header-props="headerProps" @input="setShowDate"/>
      </template>
    </calendar-view>
<!--    <ul id="example-1">-->
<!--      <li v-for="item in items" :key="item.date">-->
<!--        {{ item.startDate }}-->
<!--        {{ item.endDate }}-->
<!--        {{ item.title_time }}-->
<!--      </li>-->
<!--    </ul>-->
    <template>
      <div>
        <table class="schedule-table">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Начало</th>
            <th>Конец</th>
            <th>Время</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.date }}</td>
            <td>{{ item.startDate }}</td>
            <td>{{ item.endDate }}</td>
            <td>{{ item.title_time }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
        <el-table v-loading="loading" :data="users" border style="width: 100%">
          <el-table-column label="Дата" prop="date"></el-table-column>
          <el-table-column label="Время" prop="title_time"></el-table-column>
          <el-table-column label="Действия">
            <template slot-scope="scope">
              <table-action-column
                  :document-id="scope.row.id"
                  :show-remove="scope.row.is_archived === 0"
                  module-code="user"
                  @remove="remove(scope)"
              ></table-action-column>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :page-size="page.size"
            :total="page.total"
            layout="prev, pager, next"
            @current-change="paginate"
        ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
// import TableActionColumn from "@/components/TableActionColumn";
import axios from "axios";
//import {ref} from "vue";
// import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import {CalendarView, CalendarViewHeader, CalendarMathMixin} from "vue-simple-calendar/src/components/bundle";
import 'vue-simple-calendar/static/css/default.css'
import 'vue-simple-calendar/static/css/holidays-us.css'
import 'vue-simple-calendar/static/css/gcal.css'
import moment from "moment";
import VPopup from "@/components/PopUp";

//itemTimes= ref()

export default {
  name: "ScheduleList",

  components: {
    // TableActionColumn,
    CalendarView,
    CalendarViewHeader,
    VPopup
  },
  computed: {},
  data() {
    return {
      itemTimes: [],
      form: {
        date: null,
        time_id: null,
        requestType: null,
      },
      hours: [],
      userType: 'doctor',
      users: [],
      cities: [],
      create: false,
      page: {
        offset: 1,
        size: 20,
        total: 0
      },
      rules: {
        date: {required: true, message: "Обязательное поле", trigger: "blur"},
        time_id: {required: true, message: "Обязательное поле", trigger: "blur"}
      },
      loading: false,
      showDate: this.thisMonth(1),
      message: "",
      startingDayOfWeek: 1,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: "month",
      displayPeriodCount: 1,
      displayWeekNumbers: false,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      newItemTitle: "",
      newItemStartDate: "",
      newItemEndDate: "",
      useDefaultTheme: true,
      useHolidayTheme: true,
      useTodayIcons: false,
      items: [],
    };
  },
  mounted() {
  },
  async created() {
    try {
      console.log(this.$route.params);
      this.userType = this.$route.params.type;
      await this.getSchedule(); // Добавьте эту строку
      this.getHours();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
  },
  methods: {
    async getHours() {
      var url = `${process.env.VUE_APP_API_URL}/web/dictionaries/schedule-time`
      const result = await this.$http.post(url, {})
      this.hours = result.data.map(item => {
        return item
      })
    },
    periodChanged() {
      // range, eventSource) {
      // Demo does nothing with this information, just including the method to demonstrate how
      // you can listen for changes to the displayed range and react to them (by loading items, etc.)
      //console.log(eventSource)
      //console.log(range)
    },
    thisMonth(d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay(d) {
      this.selectionStart = null
      this.selectionEnd = null
      this.message = `You clicked: ${d.toLocaleDateString()}`
    },
    async onClickItem(item) {
      const resp = confirm('Вы действительно хотите удалить элемент?')
      if (resp) {
        const type = this.userType === 'doctor' ? 'work-schedule' : 'clinic-work-schedule';
        const field = this.userType === 'doctor' ? 'doctor_info_id' : 'clinic_info_id';
        let url = `${process.env.VUE_APP_API_URL}/admin/${type}/remove`
        await this.$http.post(url, {
              id: item.originalItem.id,
              [field]: this.$route.params.id,
            }
        )
        this.getSchedule()
      }
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`
      this.showDate = d
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1]
      this.selectionStart = dateRange[0]
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange)
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`
    },
    async onDrop(item, date) {
      this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the item.
      try {
        const type = this.userType === 'doctor' ? 'work-schedule' : 'clinic-work-schedule';
        const field = this.userType === 'doctor' ? 'doctor_info_id' : 'clinic_info_id';

        let url = `${process.env.VUE_APP_API_URL}/admin/${type}/edit`
        await this.$http.post(url, {
              id: item.originalItem.id,
              date: moment(date).format('YYYY-MM-DD'),
              [field]: this.$route.params.id,

            }
        )
        const eLength = CalendarMathMixin.methods.dayDiff(item.startDate, date)
        item.originalItem.startDate = CalendarMathMixin.methods.addDays(item.startDate, eLength)
        item.originalItem.endDate = CalendarMathMixin.methods.addDays(item.endDate, eLength)

      } catch (e) {
        console.log(e.response.status)
        if (e.response.status === 403) {
          alert('Такая дата уже есть!')
        }
      }
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: "e" + Math.random().toString(36).substr(2, 10),
      })
      this.message = "You added a calendar item!"
    },
    async getCities() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/web/dictionaries/cities`, {});
        this.cities = result.data
      } catch (e) {
        throw e;
      }
    },
    onSubmit: async function () {
      try {
        await this.$refs.form.validate(async (valid) => {
          if (valid) {
            const type = this.userType === 'doctor' ? 'work-schedule' : 'clinic-work-schedule';
            const field = this.userType === 'doctor' ? 'doctor_info_id' : 'clinic_info_id';

            console.log('success')
            const url = `${process.env.VUE_APP_API_URL}/admin/${type}/add`

            await this.$http.post(url,
                {
                  [field]: this.$route.params.id,
                  date: moment(this.form.date).format('YYYY-MM-DD'),
                  time_id: this.form.time_id,
                  record_type_clinic: this.form.requestType
                }).catch(function () {
              alert('На это время уже есть запись')
            })
            await this.getSchedule()
            this.create = !this.create;

            this.$message.success("Успешно");
          } else {
            console.log('error submit!!');
            return false;
          }
        });

      } catch (e) {
        if (e.response.status === 403) {
          alert('Такая дата уже есть!')
        } else {
          this.$message.error({
            showClose: true,
            message: this.$getErrorText(e),
            duration: 7500
          });
        }
      }
    },
    closePopup() {
      this.create = !this.create;
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getSchedule(event);
    },
    async getSchedule() {
      this.form = {
        date: null,
        time_id: null,
        requestType: null,
      }
      try {
        const type = this.userType === 'doctor' ? 'work-schedule' : 'clinic-work-schedule';
        const field = this.userType === 'doctor' ? 'doctor_info_id' : 'clinic_info_id';
        this.loading = true
        let url = `${process.env.VUE_APP_API_URL}/admin/${type}/get`
        const result = await this.$http.post(url, {
          [field]: this.$route.params.id,
          date: moment(this.showDate).format('YYYY-MM-DD')
        })
        console.log({result})
        this.items = result.data.map(item => {
          const [start, end] = item.title_time.split('-')
          const res = {
            ...item,
            title: ' ',
            requestType: item.requestType,
            startDate: `${item.date} ${start}`,
            endDate: `${item.date} ${end}`,
          }
          return res
        })
        console.log(this.items);
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getScheduleOld(page) {
      var p = ""
      if (page)
        p = "?page=" + page
      this.loading = true;
      this.users = []
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/schedule/getById${p}`,
            {
              id: this.$route.params.id,
              params: {
                offset: this.page.offset,
                limit: this.page.size
              }
            }
        );
        const data = result.data.data.map(item => {
          return item;
        });

        if (data) {
          this.users = data;
        }
        this.page.total = result.data.total;

      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    view(id) {
      this.$router.push({name: "usersViewRoute", params: {id: id}});
    },
    async remove(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/archive`,
            {id}
        );
        this.getSchedule()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    async restore(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/activate`,
            {id}
        );
        this.getSchedule()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    edit(scope) {
      this.create = true;
      this.user = {
        index: scope.$index,
        id: scope.row.id,
        name: scope.row.name,
        email: scope.row.email,
        is_archived: scope.row.is_archived,
        address: scope.row.address,
        bio: scope.row.bio,
        sex: scope.row.sex,
        city_id: scope.row.city_id,
        date_birthdate: scope.row.date_birthdate,
        work_time: scope.row.work_time,
        phone: scope.row.phone,
        description: scope.row.description,
        blocked: scope.row.blocked,
        blocked_due: scope.row.blocked_due,
      }
    }
  }
};
</script>

<style scoped>
.cv-item-offline {
  border-color: red !important;
  background-color: red !important;
  color: white !important;
}

.theme-default .cv-item-offline .startTime, .theme-default .cv-item-offline .endTime {
  font-weight: bold;
  color: white !important;
}

.calendar_view {
  height: 800px;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
}

.schedule-table th,
.schedule-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.schedule-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.schedule-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.schedule-table tbody tr:hover {
  background-color: #e6f7ff;
  cursor: pointer;
}
</style>
