<template>
  <el-card class="box-card">
    <v-popup v-show="create&&userType==='admin'" :title="user.id!=null?'Изменить':'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="user" :rules="rules" label-position="left" label-width="auto">
        <el-form-item label="Электронный адрес" prop="email">
          <el-input v-model="user.email" placeholder="Электронный адрес" type="email"></el-input>
        </el-form-item>
        <el-form-item label="ФИО" prop="name">
          <el-input v-model="user.name" placeholder="ФИО"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="user.password" autosize placeholder="..." type="password"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="user.password_confirmation" autosize placeholder="..." type="password"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create&&userType==='user'" :title="user.id!=null?'Изменить':'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="user" :rules="rules" label-position="left" label-width="auto">
        <el-form-item label="Электронный адрес" prop="email">
          <el-input v-model="user.email" placeholder="Электронный адрес" type="email"></el-input>
        </el-form-item>
        <el-form-item label="Имя" prop="name">
          <el-input v-model="user.name" placeholder="Имя"></el-input>
        </el-form-item>
        <el-form-item label="Фамилия" prop="name">
          <el-input v-model="user.surname" placeholder="Фамилия"></el-input>
        </el-form-item>
        <el-form-item label="Отчество" prop="name">
          <el-input v-model="user.patronymic" placeholder="Отчество"></el-input>
        </el-form-item>
        <el-form-item label="Номер телефона" prop="phone">
          <el-input v-model="user.phone" placeholder="Введите номер телефона"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="user.password" autosize placeholder="..." type="password"></el-input>
        </el-form-item>
        <el-form-item label="Повторите пароль" prop="password">
          <el-input v-model="user.password_confirmation" autosize placeholder="..." type="password"></el-input>
        </el-form-item>
        <el-form-item label="Дата рождения" prop="date_birthdate">
          <el-date-picker v-model="user.date_birthdate" autosize placeholder="..." type="date"></el-date-picker>
        </el-form-item>
        <el-form-item label="Пол" prop="sex">
          <el-select v-model="user.sex" autosize>
            <el-option :key="1" :value="1" label="Мужской"/>
            <el-option :key="2" :value="2" label="Женский"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Город" prop="city_id">
          <el-select v-model="user.city_id" autosize>
            <el-option v-for="citi in cities" :key="citi.id" selected :value="citi.id" :label="citi.title"/>
          </el-select>
        </el-form-item>
        <el-checkbox v-if="user.id != null" label="Блокировать" v-model="user.blocked"></el-checkbox>
        <el-form-item label="Блокировка до" prop="blocked_due" v-if="user.blocked && user.id != null">
          <el-date-picker v-model="user.blocked_due" autosize placeholder="..." type="date"></el-date-picker>
        </el-form-item>
        <br>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create&&userType==='clinic'" :title="user.id!=null?'Изменить':'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="user" :rules="rules" label-position="left" label-width="auto">
        <el-form-item label="Электронный адрес" prop="email">
          <el-input v-model="user.email" placeholder="Введите электронный адрес" type="email"></el-input>
        </el-form-item>
        <el-form-item label="Название организации" prop="name">
          <el-input v-model="user.name" placeholder="Название организации"></el-input>
        </el-form-item>
        <el-form-item label="Название организации на казахском" prop="name">
          <el-input v-model="user.name_kz" placeholder="Название организации"></el-input>
        </el-form-item>
        <el-form-item label="Название организации на английском" prop="name">
          <el-input v-model="user.name_en" placeholder="Название организации"></el-input>
        </el-form-item>
        <el-form-item label="Адрес" prop="address">
          <el-input v-model="user.address" placeholder="Адрес"></el-input>
        </el-form-item>
        <el-form-item label="Адрес на казахском" prop="address_en">
          <el-input v-model="user.address_kz" placeholder="Адрес на английском"></el-input>
        </el-form-item>
        <el-form-item label="Адрес на английском" prop="address_en">
          <el-input v-model="user.address_en" placeholder="Адрес на английском"></el-input>
        </el-form-item>
        <el-form-item label="Рабочее время" prop="work_time">
          <el-input v-model="user.work_time" v-mask="'##:## - ##:##'" placeholder="Рабочее время"/>
        </el-form-item>
        <el-form-item label="Номер телефона" prop="phone">
          <el-input v-model="user.phone" v-mask="'+7 (7##) ###-##-##'" placeholder="Номер телефона"/>
        </el-form-item>
        <el-form-item label="Описание" prop="description">
          <el-input v-model="user.description" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Описание на казахском" prop="description">
          <el-input v-model="user.description_kz" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Описание на наглийском" prop="description">
          <el-input v-model="user.description_en" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Город" prop="city_id">
          <el-select v-model="user.city_id" autosize>
            <el-option v-for="citi in cities" :key="citi.id" selected :value="citi.id" :label="citi.title"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="user.password" autosize placeholder="Введите пароль" type="password"></el-input>
        </el-form-item>
        <el-form-item label="Подтверждение пароля" prop="password">
          <el-input v-model="user.password_confirmation" autosize placeholder="Повторите пароль"
                    type="password"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <v-popup v-show="create&&userType==='doctor'" :title="user.id!=null?'Изменить':'Добавить'" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" :model="user" :rules="rules" label-position="left" label-width="auto">
        <el-form-item label="Электронный адрес" prop="email">
          <el-input v-model="user.email" placeholder="Электронный адрес" type="email"></el-input>
        </el-form-item>
        <el-form-item label="ФИО  врача" prop="name">
          <el-input v-model="user.name" placeholder="ФИО клиента"></el-input>
        </el-form-item>
        <el-form-item label="ФИО  врача на английском" prop="name">
          <el-input v-model="user.name_en" placeholder="ФИО врача на английском"></el-input>
        </el-form-item>
        <el-form-item label="ФИО  врача на казахском" prop="name">
          <el-input v-model="user.name_kz" placeholder="ФИО врача на казахском"></el-input>
        </el-form-item>
        <el-form-item label="Специальность" prop="professions">
          <el-select v-model="user.professions" multiple collapse-tags placeholder="Специальность">
            <el-option
                v-for="item in professions"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Номер телефона" prop="phone">
          <el-input v-model="user.phone" v-mask="'+7 (7##) ###-##-##'" placeholder="Введите номер"/>
        </el-form-item>
        <el-form-item label="Описание" prop="bio">
          <el-input v-model="user.bio" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Описание на казахском" prop="bio_kz">
          <el-input v-model="user.bio_kz" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Описание на английском" prop="bio_en">
          <el-input v-model="user.bio_en" placeholder="Описание" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="Тип записи" prop="description">

          <el-select
              v-model="user.requestType"
              clearable
              placeholder="Тип записи"
          >
            <el-option
                key="1"
                label="Онлайн"
                :value="1"
            >
            </el-option>
            <el-option
                key="2"
                label="Оффлайн"
                :value="2"
            >
            </el-option>
            <el-option
                key="3"
                label="Онлайн и оффлайн"
                :value="3"
            >
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="Город" prop="city_id">
          <el-select v-model="user.city_id" autosize>
            <el-option v-for="citi in cities" :key="citi.id" selected :value="citi.id" :label="citi.title"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Пароль" prop="password">
          <el-input v-model="user.password" autosize placeholder="Введите пароль" type="password"></el-input>
        </el-form-item>
        <el-form-item label="Подтверждение пароля" prop="password">
          <el-input v-model="user.password_confirmation" autosize placeholder="Повторите пароль"
                    type="password"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmit">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Пользователи</b>
      <div class="users-filter">
        <el-input clearable v-model="filter.name" style="margin: 0 0.5rem" placeholder="Фио"></el-input>
        <el-input clearable v-model="filter.email" style="margin: 0 0.5rem" placeholder="Email"></el-input>
        <el-input clearable v-mask="'+7 (###) ###-##-##'" v-model="filter.phone" style="margin: 0 0.5rem"
                  placeholder="Номер телефона"></el-input>
      </div>
      <div class="el-button el-button--primary el-button--small"
           @click="create=!create">
        Добавить
      </div>
    </el-row>
    <el-row>
      <el-tabs type="card" value="user" @tab-click="handleClick">
        <el-tab-pane label="Пользователи" name="user"></el-tab-pane>
        <el-tab-pane label="Докторы" name="doctor"></el-tab-pane>
        <el-tab-pane label="Клиники" name="clinic"></el-tab-pane>
        <el-tab-pane label="Администраторы" name="admin"></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-table v-loading="loading" :data="users" border style="width: 100%">
      <el-table-column v-if="userType!='user'" label="ФИО/Название" prop="name"></el-table-column>

      <el-table-column v-if="userType=='user'" label="Имя" prop="name"></el-table-column>
      <el-table-column v-if="userType=='user'" label="Фамилия" prop="surname"></el-table-column>
      <el-table-column v-if="userType=='user'" label="Отчество" prop="patronymic"></el-table-column>

      <el-table-column v-if="userType==='clinic' || userType=='doctor'" label="ФИО/Название на казахском"
                       prop="name_kz"></el-table-column>
      <el-table-column v-if="userType==='clinic' || userType=='doctor'" label="ФИО/Название на английском"
                       prop="name_en"></el-table-column>
      <el-table-column v-if="userType=='doctor'" label="Биография" prop="bio"></el-table-column>
      <el-table-column v-if="userType=='doctor'" label="Биография на казахском" prop="bio_kz">
        <template #default="scope">
          <span>{{ scope.row.bio_kz }}</span>
        </template>

      </el-table-column>
      <el-table-column v-if="userType=='doctor'" label="Биография на английском" prop="bio_en">
        <template #default="scope">
          <span>{{ scope.row.bio_en }}</span>
        </template>

      </el-table-column>
      <el-table-column v-if="userType=='doctor'" label="Тип записи" prop="requestType">
        <template #default="scope">
          <div v-if="scope.row.requestType==1">Онлайн</div>
          <div v-if="scope.row.requestType==2">Оффлайн</div>
          <div v-if="scope.row.requestType==3">Онлайн и оффлайн</div>
        </template>
      </el-table-column>
      <el-table-column v-if="userType==='clinic'" label="Описание клиники" prop="description"></el-table-column>
      <el-table-column v-if="userType==='clinic'" label="Описание клиники на казахском" prop="description_kz">
        <template #default="scope">
          <span>{{ scope.row.description_kz }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="userType==='clinic'" label="Описание клиники на английском" prop="description_en">
        <template #default="scope">
          <span>{{ scope.row.description_en }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Email" prop="email"></el-table-column>
      <el-table-column v-if="userType==='clinic'" label="Рабочее время" prop="work_time"></el-table-column>
      <el-table-column v-if="userType!=='admin'" label="Номер телефона">
        <template slot-scope="scope">
          <input
              v-mask="'+7 (7##) ###-##-##'"
              :value="scope.row.phone"
              class="input-read"
              disabled="disabled"
              tabindex="-1"
          />
        </template>
      </el-table-column>
      <el-table-column label="Действия">
        <template slot-scope="scope">
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="scope.row.is_archived === 0"
              :show-restore="scope.row.is_archived === 1"
              :show-view="userType === 'clinic' || userType === 'doctor'"
              :show-bind="userType === 'clinic'"
              module-code="user"
              @edit="edit(scope)"
              @remove="remove(scope)"
              @restore="restore(scope)"
              @view="view(scope)"
              @bind="bind(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
import VPopup from "@/components/PopUpBig";
import axios from "axios";

export default {
  name: "UserList",

  components: {
    VPopup,
    TableActionColumn
  },
  data() {
    return {
      userType: 'user',
      users: [],
      cities: [],
      professions: [],
      create: false,
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      filter: {
        phone: '',
        email: '',
        name: ''
      },
      user: {
        id: null,
        index: null,
        name: null,
        name_en: null,
        name_kz: null,
        surname: null,
        patronymic: null,
        email: null,
        address: null,
        address_en: null,
        address_kz: null,
        bio: null,
        bio_en: null,
        bio_kz: null,
        requestType: null,
        professions: null,
        city_id: null,
        date_birthdate: null,
        work_time: null,
        phone: null,
        description: null,
        description_en: null,
        description_kz: null,
        password: null,
        password_confirmation: null,
        blocked: false,
        blocked_due: null,
        sex: null
      },
      rules: {
        name: {required: true, message: "Обязательное поле", trigger: "blur"},
        email: {required: true, message: "Обязательное поле", trigger: "blur"},
        professions: {required: true, message: "Обязательное поле", trigger: "blur"},
        phone: {required: true, message: "Обязательное поле", trigger: "blur"},
        password: {required: true, message: "Обязательное поле", trigger: "blur"},
        work_time: {required: true, message: "Обязательное поле", trigger: "blur"},
        address: {required: true, message: "Обязательное поле", trigger: "blur"},
        city_id: {required: true, message: "Обязательное поле", trigger: "blur"},
        description: {required: true, message: "Обязательное поле", trigger: "blur"},
        bio: {required: true, message: "Обязательное поле", trigger: "blur"},
        bio_en: {required: true, message: "Обязательное поле", trigger: "blur"},
        bio_kz: {required: true, message: "Обязательное поле", trigger: "blur"},

      },
      loading: false
    };
  },
  watch: {
    'filter.phone'() {
      this.getUsers()
    },
    'filter.email'() {
      this.getUsers()
    },
    'filter.name'() {
      this.getUsers()
    }
  },
  mounted() {
  },
  async created() {
    this.getCities();
    this.getProfessions();
    try {
      await this.getUsers();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
  },
  methods: {
    async getCities() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/web/dictionaries/cities`, {});
        this.cities = result.data
      } catch (e) {
        throw e;
      }
    },
    async getProfessions() {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/web/dictionaries/professions`, {});
        this.professions = result.data
      } catch (e) {
        throw e;
      }
    },
    async onSubmit() {
      try {
        // await this.$refs.form.validate();
        let addUrl = `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/store`;
        let updateUrl = `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/update`;
        let url = (this.user.id != null && this.user.id) ? updateUrl : addUrl;
        let data = undefined;
        if (this.userType == 'user' || this.userType == 'admin') {
          this.user.name_en = ''
          this.user.name_kz = ''
        } else {
          this.user.surname = ''
          this.user.patronymic = ''
        }
        data = {
          id: this.user.id,
          name: this.user.name,
          surname: this.user.surname,
          patronymic: this.user.patronymic,
          name_en: this.user.name_en,
          name_kz: this.user.name_kz,
          email: this.user.email,
          professions: this.user.professions,
          address: this.user.address,
          address_en: this.user.address_en,
          address_kz: this.user.address_kz,
          work_time: this.user.work_time,
          bio: this.user.bio,
          bio_en: this.user.bio_en,
          bio_kz: this.user.bio_kz,
          requestType: this.user.requestType,
          sex: this.user.sex,
          city_id: this.user.city_id,
          date_birthdate: this.user.date_birthdate,
          phone: this.user.phone,
          description: this.user.description,
          description_en: this.user.description_en,
          description_kz: this.user.description_kz,
          password: this.user.password,
          password_confirmation: this.user.password_confirmation,
          blocked_due: this.user.blocked_due,
        }
        this.user.is_archived = 0
        await this.$http.post(url, data);
        this.getUsers()
        this.$message.success("Успешно");
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
      }
      this.create = !this.create;
      this.user = {
        id: null,
        index: null,
        name: null,
        patronymic: null,
        surname: null,
        email: null,
        professions: null,
        address: null,
        address_en: null,
        address_kz: null,
        date_birthdate: null,
        work_time: null,
        bio: null,
        bio_en: null,
        bio_kz: null,
        requestType: null,
        city_id: null,
        sex: null,
        phone: null,
        description: null,
        description_en: null,
        description_kz: null,
        password: null,
        password_confirmation: null
      }
    },
    closePopup() {
      this.create = !this.create;
      this.user = {
        id: null,
        index: null,
        name: null,
        patronymic: null,
        surname: null,
        name_en: null,
        name_kz: null,
        email: null,
        professions: null,
        address: null,
        address_en: null,
        address_kz: null,
        work_time: null,
        date_birthdate: null,
        bio: null,
        bio_en: null,
        bio_kz: null,
        requestType: null,
        sex: null,
        city_id: null,
        phone: null,
        description: null,
        description_en: null,
        description_kz: null,
        password: null,
        password_confirmation: null,
        blocked: false,
        blocked_due: null
      }
    },
    async paginate(event) {
      this.page.offset = event;
      console.log({event})
      await this.getUsers(event);
    },
    handleClick(item) {
      this.userType = item.name;
      this.getUsers()
    },
    async getUsers(page) {
      var p = ""
      if (page)
        p = "?page=" + page
      this.loading = true;
      this.users = []
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/get${p}`,
            {
              params: {
                offset: this.page.offset,
                limit: this.page.size
              },
              user_search: this.filter.name,
              email_search: this.filter.email,
              phone_search: this.filter.phone.replace(/[^0-9]/g, ""),
            }
        );
        const data = result.data.data.map(item => {
          item.type = this.userType;
          item.professions = item.professions?.map(prof => prof.id)
          return item;
        });

        if (data) {
          this.users = data;
        }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;

      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    view(scope) {
      console.log({scope})
      this.$router.push({name: "scheduleListRoute", params: {id: scope.row.id, type: scope.row.type}});
    },
    bind(scope) {
      this.$router.push({name: "bindDoctor", params: {id: scope.row.id}});
    },
    async remove(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/archive`,
            {id}
        );
        this.getUsers()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    async restore(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/activate`,
            {id}
        );
        this.getUsers()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    edit(scope) {
      this.create = true;
      this.user = {
        index: scope.$index,
        id: scope.row.id,
        name: scope.row.name,
        patronymic: scope.row.patronymic,
        surname: scope.row.surname,
        name_en: scope.row.name_en,
        name_kz: scope.row.name_kz,
        email: scope.row.email,
        professions: scope.row.professions,
        is_archived: scope.row.is_archived,
        address: scope.row.address,
        address_en: scope.row.address_en,
        address_kz: scope.row.address_kz,
        bio: scope.row.bio,
        bio_en: scope.row.bio_en,
        bio_kz: scope.row.bio_kz,
        requestType: scope.row.requestType,
        sex: scope.row.sex,
        city_id: this.users.find(user => user.id === scope.row.id).city_id,
        date_birthdate: scope.row.date_birthdate,
        work_time: scope.row.work_time,
        phone: scope.row.phone,
        description: scope.row.description,
        description_en: scope.row.description_en,
        description_kz: scope.row.description_kz,
        blocked: scope.row.blocked,
        blocked_due: scope.row.blocked_due,
      }
      console.log(this.user)
    }
  }
};
</script>

<style>
.users-filter {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 2rem;
}
</style>
