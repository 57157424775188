<template>
  <el-button-group>
    <el-button
      type="primary"
      size="mini"
      icon="el-icon-view"
      @click="$emit('view')"
      title="Просмотр"
      v-if="showView"
    ></el-button>
    <el-button
      type="warning"
      size="mini"
      icon="el-icon-user-solid"
      @click="$emit('bind')"
      title="Привязать доктора"
      v-if="showBind"
    ></el-button>
    <el-button
        type="primary"
        icon="el-icon-check"
        @click="$emit('remove')"
        title="Отключить"
        v-if="showHandle"
    ></el-button>
    <el-button
        type="primary"
        icon="el-icon-refresh"
        @click="$emit('restore')"
        title="Включить"
        v-if="showHandle"
    ></el-button>
    <el-button
      type="success"
      size="mini"
      icon="el-icon-edit"
      @click="$emit('edit')"
      title="Редактировать"
      v-if="showEdit"
    ></el-button>
    <el-button
        @click="remove()"
        type="danger"
        icon="el-icon-delete"
        size="mini"
        title="Удалить"
        v-if="showRemove"
    ></el-button>
    <el-button
        @click="restore()"
        type="info"
        icon="el-icon-refresh"
        size="mini"
        title="Восстановить"
        v-if="showRestore"
    ></el-button>
    <document-operation
      @execute="$emit('execute')"
      :module-id="moduleId"
      :document-id="documentId"
      v-if="showOperation && moduleId"
    ></document-operation>
    <span>
      <el-dropdown trigger="click" v-if="showHistory">
        <el-button
          icon="el-icon-notebook-1"
          size="mini"
          class="el-button--info"
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <router-link
              class="el-link el-link--info"
              :to="{
                name: 'journalDocument',
                params: {
                  documentId: documentId,
                  moduleId: moduleId
                }
              }"
              >Журнал по документу
            </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </span>
    <slot></slot>
  </el-button-group>
</template>

<script>
import DocumentOperation from "@/components/DocumentOperation";
import { mapState } from "vuex";

export default {
  name: "TableActionColumn",
  components: { DocumentOperation },
  props: {
    moduleCode: String,
    documentId: Number,
    showView: {
      type: Boolean,
      default: true
    },
    showHandle: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: true
    },
    showBind: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: true
    },
    showRestore: {
      type: Boolean,
      default: true
    },
    showOperation: {
      type: Boolean,
      default: true
    },
    showHistory: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["reference"]),
    moduleId() {
      return this.reference.modules
        .filter(m => m.code.toLowerCase() === this.moduleCode)
        .map(i => i.id)[0];
    }
  },
  methods: {
    remove() {
      this.$confirm("Подтвердите своё действие", "Внимание", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning"
      })
          .then(() => {
            this.$emit("remove");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Действие отменено"
            });
          });
    },
    restore() {
      this.$confirm("Подтвердите своё действие", "Внимание", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning"
      })
          .then(() => {
            this.$emit("restore");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Действие отменено"
            });
          });
    }
  }
};
</script>

<style scoped></style>
