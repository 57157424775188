<template>
  <span>
    <el-dropdown trigger="click" size="medium" placement="bottom-start">
      <el-button
        type="primary"
        :size="size"
        @click.native="getOperations"
        title="Операции"
      >
        <i class="el-icon-s-operation"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="operation in operations"
          :key="operation.id"
          @click.native="checkOperation(operation)"
          >{{ operation.name }}</el-dropdown-item
        >
        <template v-if="operations.length === 0">
          <template v-if="loading">
            <i class="el-icon-loading"></i>
          </template>
          <template v-else>
            <el-dropdown-item>Нет доступных операций</el-dropdown-item>
          </template>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog :title="operation.name" :visible.sync="dialogFormVisible">
      <el-form :model="operation.form" ref="form">
        <el-form-item
          v-for="(domain, index) in operation.form.domains"
          :label="domain.par_name"
          :key="domain.key"
          :prop="'domains[' + index + '].value'"
          :rules="domain.rules"
        >
          <template v-if="domain.par_type === 'integer'">
            <el-input-number
              v-model.number="domain.value"
              controls-position="right"
            ></el-input-number>
          </template>
          <template v-else-if="domain.par_type === 'varchar' && domain.max">
            <el-input
              v-model="domain.value"
              type="textarea"
              :maxlength="domain.max"
              :show-word-limit="true"
            ></el-input>
          </template>
          <template v-else>
            <el-input v-model="domain.value" type="text"></el-input>
          </template>
          <!-- <el-input
            v-model="domain.value"
            autocomplete="off"

            :maxlength="domain.max"
            :show-word-limit="true"
          ></el-input>-->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Отмена</el-button>
        <el-button type="primary" @click="submit">ОК</el-button>
      </span>
    </el-dialog>
  </span>
</template>

<script>
import { logger } from "../modules/logger";

export default {
  name: "DocumentOperation",
  props: {
    moduleId: Number,
    documentId: Number,
    size: {
      type: String,
      default: "mini"
    }
  },
  data() {
    return {
      operations: [],
      operation: {
        name: "",
        params: [],
        form: {
          domains: []
        }
      },
      dialogFormVisible: false,
      loading: false
    };
  },
  methods: {
    async getOperations() {
      this.loading = true;
      try {
        const result = await this.$http.get("/v1/operations/getAvailable", {
          params: {
            mod_id: this.moduleId,
            doc_id: this.documentId
          }
        });
        this.operations = result.data.items;
      } catch (e) {
        logger.error(e);
      }
      this.loading = false;
    },
    async checkOperation(operation) {
      this.operation.id = operation.id;
      this.operation.code = operation.code;
      this.operation.name = operation.name;
      this.operation.params = operation.params;
      this.operation.is_dlg = operation.is_dlg;
      if (this.operation.is_dlg) {
        const message = await this.getDialogMessage();
        this.$confirm(message, "Подтвердите свои действия", {
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
          type: "warning"
        })
          .then(() => {
            this.secondDialog();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Операция отменена"
            });
          });
      } else {
        this.secondDialog();
      }
    },
    async secondDialog() {
      try {
        if (this.operation.params) {
          const params = JSON.parse(this.operation.params);
          this.operation.form.domains = params.map(param => {
            const rules = [
              { required: true, message: "Обязательное поле", trigger: "blur" }
            ];
            if (param.par_type === "integer") {
              rules.push({
                type: "number",
                message: "Введите число",
                trigger: "blur"
              });
            } else {
              const match = param.par_type.match(/\d+/g);
              param.max = null;
              if (match) {
                param.max = match.map(Number)[0];
              }
            }
            return {
              key: param.par_code,
              value: "",
              max: param.max,
              rules: rules,
              par_name: param.par_name,
              par_type: param.par_type
            };
          });
          this.operation.params = params;
          this.dialogFormVisible = true;
        } else {
          await this.exec();
        }
      } catch (e) {
        this.$message.error({
          duration: 15000,
          message: this.$getErrorText(e),
          showClose: true
        });
      }
    },
    async getDialogMessage() {
      try {
        const result = await this.$http.get("/v1/operations/dialog", {
          params: {
            mod_id: this.moduleId,
            doc_id: this.documentId,
            op_id: this.operation.id
          }
        });
        return result.data ? result.data.result_descr : "Подтвердите";
      } catch (err) {
        this.$message.error({
          duration: 15000,
          message: this.$getErrorText(err),
          showClose: true
        });
      }
    },
    async exec() {
      try {
        await this.$http.post("/v1/operations/execute", {
          mod_id: this.moduleId,
          doc_id: this.documentId,
          oper_id: this.operation.id,
          params: "{}"
        });
        this.$emit("execute", this.documentId);
        this.operations = {
          name: "",
          params: [],
          form: {
            domains: []
          }
        };
        this.operations = [];
      } catch (e) {
        this.$message.error({
          duration: 15000,
          message: this.$getErrorText(e),
          showClose: true
        });
      }
    },
    async submit() {
      try {
        const valid = await this.$refs.form.validate();
        if (valid) {
          const params = this.operation.form.domains.map(item => {
            const obj = {};
            obj[item.key] = item.value;
            return obj;
          })[0];
          const result = await this.$http.post("/v1/operations/execute", {
            mod_id: this.moduleId,
            doc_id: this.documentId,
            oper_id: this.operation.id,
            params: JSON.stringify(params)
          });
          this.$emit("execute", this.documentId);
          this.operations = {
            name: "",
            params: [],
            form: {
              domains: []
            }
          };
          this.operations = [];
          logger.log(result);
          this.dialogFormVisible = false;
        } else {
          this.$message.error("Ошибка проверьте обязаельные поля");
          return false;
        }
      } catch (e) {
        this.$message.error({
          duration: 15000,
          message: this.$getErrorText(e),
          showClose: true
        });
      }
    }
  }
};
</script>

<style scoped>
.el-button__operation {
  border-radius: 0;
}
</style>
