<template>
  <el-card class="box-card">
    <v-popup v-show="create" title="Добавить" @closePopup="closePopup">
      <el-form ref="form" v-loading="loading" label-position="left" label-width="auto">
        <el-form-item label="Доктор" prop="sex">
          <el-select v-model="doctor_id" default-first-option autosize remote :remote-method="uploadList"
                     >
            <div @scroll="uploadList">
              <el-option :key="0" value="" label="Выберите доктора"/>
              <el-option v-for="doc in doctors" :key="doc.id" :value="doc.id" :label="doc.name"/>
            </div>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="bindDoctor">Сохранить</el-button>
        <go-back @closePopup="closePopup"></go-back>
      </el-form>
    </v-popup>
    <el-row slot="header" align="middle" justify="space-between" type="flex">
      <b>Список привязанных врачей</b>
      <div class="el-button el-button--primary el-button--small"
           @click="create=!create">
        Добавить
      </div>
    </el-row>
    <el-table v-loading="loading" :data="users" border style="width: 100%">
      <el-table-column label="ФИО/Название" prop="name"></el-table-column>
      <el-table-column label="Email" prop="email"></el-table-column>
      <el-table-column v-if="userType==='clinic'" label="Рабочее время" prop="work_time"></el-table-column>
      <el-table-column v-if="userType!=='admin'" label="Номер телефона">
        <template slot-scope="scope">
          <input
              v-mask="'+7 (7##) ###-##-##'"
              :value="scope.row.phone"
              class="input-read"
              disabled="disabled"
              tabindex="-1"
          />
        </template>
      </el-table-column>
      <el-table-column label="Действия">
        <template slot-scope="scope">
          <table-action-column
              :document-id="scope.row.id"
              :show-remove="true"
              :show-restore="false"
              :show-view="false"
              :show-edit="false"
              module-code="user"
              @remove="remove(scope)"
          ></table-action-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="page.size"
        :total="page.total"
        :current-page="page.current_page"
        layout="prev, pager, next"
        @current-change="paginate"
    ></el-pagination>
  </el-card>
</template>

<script>
import {logger} from "@/modules/logger";
import TableActionColumn from "@/components/TableActionColumn";
import VPopup from "@/components/PopUp";

export default {
  name: "BindDoctor",

  components: {
    VPopup,
    TableActionColumn
  },
  data() {
    return {
      userType: 'user',
      users: [],
      doctors: [],
      cities: [],
      professions: [],
      create: false,
      doctor_id: '',
      page: {
        offset: 1,
        size: 15,
        total: 0
      },
      user: {
        id: null,
        index: null,
        name: null,
        email: null,
        address: null,
        bio: null,
        professions: null,
        city_id: null,
        date_birthdate: null,
        work_time: null,
        phone: null,
        description: null,
        password: null,
        password_confirmation: null,
        blocked: false,
        blocked_due: null,
        sex: null
      },
      rules: {
        name: {required: true, message: "Обязательное поле", trigger: "blur"},
        email: {required: true, message: "Обязательное поле", trigger: "blur"},
        professions: {required: true, message: "Обязательное поле", trigger: "blur"},
        phone: {required: true, message: "Обязательное поле", trigger: "blur"},
        password: {required: true, message: "Обязательное поле", trigger: "blur"}
      },
      loading: false
    };
  },
  mounted() {
  },
  async created() {
    try {
      await this.getUsers();
      await this.getDoctors();
    } catch (e) {
      this.$message.error({
        showClose: true,
        message: this.$getErrorText(e),
        duration: 7500
      });
      logger.error(e);
    }
  },
  methods: {
    uploadList() {
      console.log('upload')
    },

    async bindDoctor() {
      this.loading = true;
      // eslint-disable-next-line no-useless-catch
      try {
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/clinic/bind`,
            {
              id_clinic: this.$route.params.id,
              id_doctor: this.doctor_id,
            }
        );
        this.$message.success("Успешно");
        await this.getUsers()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        throw e;
      } finally {
        this.loading = false;
        this.create = !this.create;
      }
    },
    closePopup() {
      this.create = !this.create;
      this.user = {
        id: null,
        index: null,
        name: null,
        email: null,
        professions: null,
        address: null,
        work_time: null,
        date_birthdate: null,
        bio: null,
        sex: null,
        city_id: null,
        phone: null,
        description: null,
        password: null,
        password_confirmation: null,
        blocked: false,
        blocked_due: null
      }
    },
    async paginate(event) {
      this.page.offset = event;
      await this.getUsers(event);
    },
    handleClick(item) {
      this.userType = item.name;
      this.getUsers()
    },
    async getUsers(page) {
      var p = ""
      if (page)
        p = "?page=" + page
      this.loading = true;
      this.users = []
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/clinic/binded${p}`,
            {
              id_clinic: this.$route.params.id,
              params: {
                offset: this.page.offset,
                limit: this.page.size
              }
            }
        );

        const data = result.data.data.map(item => {
          return item;
        });


        if (data) {
          this.users = data;
        }
        this.page.size = result.data.per_page;
        this.page.current_page = result.data.current_page;
        this.page.total = result.data.total;
      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    async getDoctors() {
      this.loading = true;
      // this.doctors = []
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/doctor/all`,
        );
        const data = result.data.map(item => {
          return item;
        });


        if (data) {
          this.doctors = data;
        }

      } catch (e) {
        throw e;
      }
      this.loading = false;
    },
    view(scope) {
      this.$router.push({name: "scheduleListRoute", params: {id: scope.row.id}});
    },
    async remove(scope) {
      try {
        const id = scope.row.doctor_info_id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/clinic/unbind`,
            {
              id_doctor: id,
              id_clinic: this.$route.params.id,
            }
        );
        this.getUsers()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
    async restore(scope) {
      try {
        const id = scope.row.id;
        await this.$http.post(
            `${process.env.VUE_APP_API_URL}/admin/users/${this.userType}/activate`,
            {id}
        );
        this.getUsers()
      } catch (e) {
        this.$message.error({
          showClose: true,
          message: this.$getErrorText(e),
          duration: 7500
        });
        logger.error(e);
      }
    },
  }
};
</script>

<style></style>
