var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-button-group',[(_vm.showView)?_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-view","title":"Просмотр"},on:{"click":function($event){return _vm.$emit('view')}}}):_vm._e(),(_vm.showBind)?_c('el-button',{attrs:{"type":"warning","size":"mini","icon":"el-icon-user-solid","title":"Привязать доктора"},on:{"click":function($event){return _vm.$emit('bind')}}}):_vm._e(),(_vm.showHandle)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-check","title":"Отключить"},on:{"click":function($event){return _vm.$emit('remove')}}}):_vm._e(),(_vm.showHandle)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-refresh","title":"Включить"},on:{"click":function($event){return _vm.$emit('restore')}}}):_vm._e(),(_vm.showEdit)?_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-edit","title":"Редактировать"},on:{"click":function($event){return _vm.$emit('edit')}}}):_vm._e(),(_vm.showRemove)?_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"mini","title":"Удалить"},on:{"click":function($event){return _vm.remove()}}}):_vm._e(),(_vm.showRestore)?_c('el-button',{attrs:{"type":"info","icon":"el-icon-refresh","size":"mini","title":"Восстановить"},on:{"click":function($event){return _vm.restore()}}}):_vm._e(),(_vm.showOperation && _vm.moduleId)?_c('document-operation',{attrs:{"module-id":_vm.moduleId,"document-id":_vm.documentId},on:{"execute":function($event){return _vm.$emit('execute')}}}):_vm._e(),_c('span',[(_vm.showHistory)?_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('el-button',{staticClass:"el-button--info",attrs:{"icon":"el-icon-notebook-1","size":"mini"}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('router-link',{staticClass:"el-link el-link--info",attrs:{"to":{
              name: 'journalDocument',
              params: {
                documentId: _vm.documentId,
                moduleId: _vm.moduleId
              }
            }}},[_vm._v("Журнал по документу ")])],1)],1)],1):_vm._e()],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }